import React from "react";

function CircleCheck() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            fill="none"
            viewBox="0 0 21 21"
        >
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M8.929 15L5 11.161l1.108-1.082 2.82 2.748L14.893 7 16 8.09 8.929 15z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M10.5 20a9.5 9.5 0 100-19 9.5 9.5 0 000 19zm0 1C16.299 21 21 16.299 21 10.5S16.299 0 10.5 0 0 4.701 0 10.5 4.701 21 10.5 21z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default CircleCheck;
