import Image from "next/image";
import { ReactElement } from "react";
import CircleCheck from "../svg/circleCheck";
import style from "./style.module.scss";

export default function CancerTypes(): ReactElement {
    const cancerTypes = [
        "Bladder Cancer",
        "Esophageal Cancer",
        "Gastric Cancer",
        "Liver Cancer",
        "Pancreatic Cancer",
        "Stomach Cancer",
    ];
    return (
        <div className={style.wrapper}>
            <div className="mx-auto lg:max-w-7xl flex justify-center items-center flex-col md:flex-row">
                <div className="relative w-[300px] h-[184px] md:w-[427px] md:h-[284px] mb-10 md:mb-0">
                    <Image
                        src="/bestzantaclawyers/assets/images/doctorOpt.png"
                        alt="doctor"
                        layout="fill"
                    />
                </div>
                <div className="md:w-2/3 md:ml-16">
                    <h2 className="h-1/2">
                        Financial Compensation May Be Available Through a Zantac
                        Lawsuit for Individuals Diagnosed with Any of the
                        Following Types of Cancers:
                    </h2>
                    <ul className="w-full flex flex-col h-1/2 max-h-28 flex-wrap">
                        {cancerTypes.map((cancerType, index) => (
                            <li key={index} className="flex">
                                <CircleCheck />
                                <span className="ml-2">{cancerType}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}
